<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import Jobs from '../Jobs';
import tinymce from '@/shared/tinymce';

export default {
  name: "Details",
  components: {'tinymceEditor': Editor},
  mixins: [Jobs, tinymce],
  data: function () {
    return {
      job: {
        id: null,
        name: '',
        description: '',
        field: {
          id: '',
          name: '',
        },
        branch: {
          id: null,
          name: '',
        },
      },
      fields: [],
      options: [],
      requestTableFields: [
        {
          key: 'name',
          label: 'Name',
          formatter: /* istanbul ignore next */ (value, key, item) => {
            let first_name =  item.fields.find(function(item){
              return item.name === 'Vorname';
            });
            let last_name =  item.fields.find(function(item){
              return item.name === 'Nachname';
            });

            return first_name.value + ' ' + last_name.value;
          }
        },
        { key: 'timestamp', label: 'Datum', formatter: value => { return this.$options.filters.formatDate(value) }},
        { key: 'done', label: 'Erledigt' },
      ],
    }
  },
  created: function(){
    let self = this;
    Http.request('GET', '/jobs/fields').then(function (response) {
      self.fields = response.data;
    });
    Http.request('GET', '/user/branches').then(function (response) {
      self.options = response.data.map(branch => {return {value: branch.id, text: branch.name};});
      self.options.unshift({value: null, text: 'Alle Filialen'});
    });
    if (self.$route.params.id > 0) {
      Http.request('GET', '/jobs/' + self.$route.params.id).then(function (response) {
        if (response.data.branch === null) {
          response.data.branch = {
            id: null,
            name: '',
          };
        }
        self.job = response.data;
      });
    }
  },
  methods: {
    saveJob () {
      let self = this;
      Http.request('POST', '/jobs/' + self.job.id, self.job)
        .then(function (response) {
          return self.$router.push('/website/jobs');
        });
    },
    setDone(entry){
      let done = entry.done ? 0 : 1;
      Http.request('POST', '/requests/' + entry.id + '/done/' + done).then(() => {
        entry.done = done === 1;
      });
    },
  }
}
</script>

<style scoped></style>
